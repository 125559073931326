import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/use-auth";
import MultiStepForm from "components/MultiStepForm";
import ParticlesComponent from "components/ui/particles";

const HomePage = () => {
    const { isAuth } = useAuth();

    return isAuth ? (
        <>
            <ParticlesComponent id="particle" />
            <MultiStepForm />
        </>
    ) : (
        <Navigate to="/login" />
    );
};

export default HomePage;
