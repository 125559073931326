import React from "react";
import { Typography } from "@mui/material";

const DocumentContent = ({ formData }) => {
  const { fullName, iin } = formData;

  return (
    <Typography>
      Соглашение на сбор и обработку персональных данных
      <br />
      1. Я {fullName || "________________________"} ИИН{" "}
      {iin || "________________"} по своей воле и в своем интересе предоставляю 
      согласие в Автосалон на сбор и обработку моих персональных данных (включая 
      фамилию имя отчество (при его наличии) национальность пол
      дату и место рождения индивидуальный идентификационный номер юридический
      адрес место жительство абонентский номер средства связи номер документа
      удостоверяющего его личность семейное и социальное положение наличие
      движимого и недвижимого имущества образование профессию) и биометрических
      персональных данных (далее – персональные данные) (как средствами
      автоматизации так и без таковых) включая действия по получению Автосалона
      моих персональных данных по их подтверждению актуализации (обновлению)
      хранению из государственной (-ых) базы (баз) данных («ГБД») и/или иных
      любых объектов информатизации в том числе посредством кредитного (-ых)
      бюро сервиса по ГБД операторов / владельцев ГБД;  на раскрытие /
      использование / передачу (включая трансграничную передачу) в необходимом
      объеме любой информации имеющейся в распоряжении в Автосалон в том числе
      составляющей мои персональные данные и/или банковскую тайну третьим лицам
      в том числе членам а также осуществление Автосалоном и третьими лицами
      любых действий с моими персональными данными включая действия по доступу
      сбору систематизации накоплению подтверждению хранению обновлению
      изменению использованию распространению обезличиванию блокированию и
      уничтожению моих персональных данных для целей надлежащего проведения
      анализа предоставленной мной информации установления деловых отношений
      своевременной подготовки документов и подписания мною всех необходимых
      документов предотвращения мошенничества для аудита отчетности
      предоставления услуг компании и/или любой третьей стороной;  на
      произведение фотосъемки (фотокадра) и/или звуко - и/или видеозаписи а
      также на осуществление сбора / подтверждения / обновления / хранения /
      использования / копирования / осуществления передачи и воспроизведения в
      том числе в присутствии третьих лиц моего изображения (фото / видео со
      звуком / без звука) и (или) голосового (-ых) сообщения (-й).
      <br />
      2. Подтверждаю что в случае отсутствия гарантирую получение и наличие
      согласий третьих лиц информация в отношении которых предоставляется мной в
      автосалон в любой форме на передачу их данных Банку и на их сбор
      <br />
      3. Принимаю на себя обязательства уведомления таких лиц об этом
      <br />
      4. Настоящее Согласие распространяется и на информацию которая поступит в
      Банк в будущем.
      <br />
      Открыв данный документ я даю полное согласие на вышеуказанные действия
      Автосалона
    </Typography>
  );
};

export default DocumentContent;
