import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const SubmissionResult = ({ success, onRetry }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ padding: isMobile ? 2 : 3, textAlign: "center" }}>
      <Typography variant="h6">
        {success ? "Данные успешно отправлены!" : "Ошибка при отправке данных."}
      </Typography>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={onRetry}>
          Заново
        </Button>
      </Box>
    </Box>
  );
};

export default SubmissionResult;
