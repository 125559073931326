import { useSelector } from "react-redux";

export const useAuth = () => {
    const { email, id, token, tg_id } = useSelector((state) => state.user);
    return {
        isAuth: !!email,
        email,
        id,
        token,
        tg_id,
    };
};
