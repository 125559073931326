import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Typography,
    Alert,
    TextField,
    Autocomplete,
    Checkbox,
    Button,
    FormControlLabel,
    Radio,
    FormLabel,
    RadioGroup,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

const banks = [
    "Во все Банки",
    "Sapa Digital Finance",
    "Халык Банк",
    "БЦК",
    "Шинхан Финанс",
    "Береке Банк",
];

const AutoCredit = ({ dataChange, formData = {} }) => {
    const [selectedBanks, setSelectedBanks] = useState(
        formData.selectedBanks || []
    );
    const [uploadedDocs, setUploadedDocs] = useState(
        formData.uploadedDocs || {}
    );
    const [acknowledged, setAcknowledged] = useState(false);
    const [carCost, setCarCost] = useState(formData.carCost || "");
    const [initialPayment, setInitialPayment] = useState(
        formData.initialPayment || ""
    );
    const [loanAmount, setLoanAmount] = useState(formData.loanAmount || "");
    const [loanTerm, setLoanTerm] = useState(formData.loanTerm || "");

    const updateFormData = useCallback(() => {
        dataChange(2, {
            selectedBanks,
            carCost,
            initialPayment,
            loanAmount,
            loanTerm,
            uploadedDocs,
        });
    }, [
        dataChange,
        selectedBanks,
        carCost,
        initialPayment,
        loanAmount,
        loanTerm,
        uploadedDocs,
    ]);

    useEffect(() => {
        // Проверяем, что данные действительно изменились, прежде чем обновлять
        const formDataChanged =
            JSON.stringify({
                selectedBanks,
                carCost,
                initialPayment,
                loanAmount,
                loanTerm,
                uploadedDocs,
            }) !== JSON.stringify(formData);

        if (formDataChanged) {
            updateFormData();
        }
    }, [
        selectedBanks,
        carCost,
        initialPayment,
        loanAmount,
        loanTerm,
        uploadedDocs,
        formData,
        updateFormData,
    ]);

    const handleBankChange = (event, value) => {
        if (value.includes("Во все Банки")) {
            setSelectedBanks(banks.slice(1));
        } else {
            setSelectedBanks(value);
        }

        if (!value.includes("Шинхан Финанс")) {
            const newDocs = { ...uploadedDocs };
            delete newDocs["clientForm"];
            delete newDocs["clientConsent"];
            delete newDocs["clientConsentPKB"];
            setUploadedDocs(newDocs);
        }
    };

    const handleFileChange = (event, docType) => {
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split(".").pop();
            let newFileName;
            switch (docType) {
                case "clientForm":
                    newFileName = `Анкета_клиента.${fileExtension}`;
                    break;
                case "clientConsent":
                    newFileName = `Согласие_клиента.${fileExtension}`;
                    break;
                case "clientConsentPKB":
                    newFileName = `Согласие_в_ПКБ.${fileExtension}`;
                    break;
                default:
                    newFileName = file.name;
            }
            const newFile = new File([file], newFileName, { type: file.type });
            setUploadedDocs((prev) => ({ ...prev, [docType]: newFile }));
        }
    };

    const handleAcknowledge = () => {
        setAcknowledged(true);
    };

    const handleCarCostChange = (event) => {
        const value = event.target.value.replace(/\s/g, "");
        setCarCost(value);
        const initialPaymentValue = (value * 0.1).toFixed(0);
        setInitialPayment(initialPaymentValue);
        setLoanAmount((value - initialPaymentValue).toFixed(0));
    };

    const handleInitialPaymentChange = (event) => {
        const value = event.target.value.replace(/\s/g, "");
        setInitialPayment(value);
        setLoanAmount((carCost - value).toFixed(0));
    };

    const handleLoanTermChange = (event) => {
        setLoanTerm(event.target.value);
    };

    return (
        <>
            {!acknowledged && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    Внимание! Автокредитование имеет комиссию в размере 1% от
                    общей стоимости автомобиля. Данная комиссия будет включена в
                    сумму кредита Заемщика!
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 2,
                        }}
                    >
                        <Button variant="contained" onClick={handleAcknowledge}>
                            Ознакомлен
                        </Button>
                    </Box>
                </Alert>
            )}
            {acknowledged && (
                <>
                    <Box mt={2}>
                        <Typography variant="h6">Выбор Банка</Typography>
                        <Autocomplete
                            multiple
                            options={banks}
                            disableCloseOnSelect
                            value={selectedBanks}
                            onChange={handleBankChange}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Выберите банки"
                                    placeholder="Банки"
                                />
                            )}
                            sx={{ mt: 1, mb: 2 }}
                        />
                    </Box>
                    {selectedBanks.includes("Шинхан Финанс") && (
                        <Box mt={2}>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                Внимание! Для подачи заявки в Шинхан Финанс
                                требуется подписать и вложить следующие
                                документы.
                            </Alert>
                            <Typography variant="h6">
                                Загрузка документов
                            </Typography>
                            <Button
                                variant="contained"
                                size="small"
                                href="https://drive.google.com/drive/folders/1WL_s7rzA7xoyI3Pch76SAMaEloMMtIBl?usp=sharing"
                                target="_blank"
                                sx={{ mb: 2 }}
                            >
                                Скачать документы для подписи
                            </Button>
                            <Box mt={2}>
                                <Typography variant="body1">
                                    Загрузить анкету клиента
                                </Typography>
                                <input
                                    accept=".pdf, image/*"
                                    style={{ display: "none" }}
                                    id="upload-client-form"
                                    type="file"
                                    onChange={(event) =>
                                        handleFileChange(event, "clientForm")
                                    }
                                />
                                <label htmlFor="upload-client-form">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        size="small"
                                    >
                                        Загрузить документ
                                    </Button>
                                </label>
                                {uploadedDocs["clientForm"] && (
                                    <Typography mt={1}>
                                        {uploadedDocs["clientForm"].name}
                                    </Typography>
                                )}
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1">
                                    Загрузить согласие клиента
                                </Typography>
                                <input
                                    accept=".pdf, image/*"
                                    style={{ display: "none" }}
                                    id="upload-client-consent"
                                    type="file"
                                    onChange={(event) =>
                                        handleFileChange(event, "clientConsent")
                                    }
                                />
                                <label htmlFor="upload-client-consent">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        size="small"
                                    >
                                        Загрузить документ
                                    </Button>
                                </label>
                                {uploadedDocs["clientConsent"] && (
                                    <Typography mt={1}>
                                        {uploadedDocs["clientConsent"].name}
                                    </Typography>
                                )}
                            </Box>
                            <Box mt={2}>
                                <Typography variant="body1">
                                    Загрузить согласие в ПКБ
                                </Typography>
                                <input
                                    accept=".pdf, image/*"
                                    style={{ display: "none" }}
                                    id="upload-client-consent-pkb"
                                    type="file"
                                    onChange={(event) =>
                                        handleFileChange(
                                            event,
                                            "clientConsentPKB"
                                        )
                                    }
                                />
                                <label htmlFor="upload-client-consent-pkb">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        size="small"
                                    >
                                        Загрузить документ
                                    </Button>
                                </label>
                                {uploadedDocs["clientConsentPKB"] && (
                                    <Typography mt={1}>
                                        {uploadedDocs["clientConsentPKB"].name}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box mt={2}>
                        <NumericFormat
                            customInput={TextField}
                            thousandSeparator=" "
                            label="Общая стоимость автомобиля (₸)"
                            value={carCost}
                            onChange={handleCarCostChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <NumericFormat
                            customInput={TextField}
                            thousandSeparator=" "
                            label="Первоначальный взнос 10% (₸)"
                            value={initialPayment}
                            onChange={handleInitialPaymentChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <NumericFormat
                            customInput={TextField}
                            label="Сумма кредита (₸)"
                            thousandSeparator=" "
                            fullWidth
                            value={loanAmount}
                            margin="normal"
                            disabled
                        />
                        <Box mt={2}>
                            <FormLabel component="legend">
                                Срок кредита
                            </FormLabel>
                            <RadioGroup
                                value={loanTerm}
                                onChange={handleLoanTermChange}
                                row
                            >
                                <FormControlLabel
                                    value="12"
                                    control={<Radio />}
                                    label="12 мес"
                                />
                                <FormControlLabel
                                    value="24"
                                    control={<Radio />}
                                    label="24 мес"
                                />
                                <FormControlLabel
                                    value="36"
                                    control={<Radio />}
                                    label="36 мес"
                                />
                                <FormControlLabel
                                    value="48"
                                    control={<Radio />}
                                    label="48 мес"
                                />
                                <FormControlLabel
                                    value="60"
                                    control={<Radio />}
                                    label="60 мес"
                                />
                                <FormControlLabel
                                    value="72"
                                    control={<Radio />}
                                    label="72 мес"
                                />
                                <FormControlLabel
                                    value="84"
                                    control={<Radio />}
                                    label="84 мес"
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

export default AutoCredit;
