import { CircularProgress, Container, Typography } from "@mui/material";
import React from "react";

const Loading = () => {
    return (
        <Container
            maxWidth="xs"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
            }}
        >
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
                Загрузка...
            </Typography>
        </Container>
    );
};

export default Loading;
