import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const WorkDetails = ({ next, back, dataChange, formData = {} }) => {
    const [position, setPosition] = useState(formData.position || "");
    const [workPlace, setWorkPlace] = useState(formData.workPlace || "");
    const [workAddress, setWorkAddress] = useState(formData.workAddress || "");
    const [workContacts, setWorkContacts] = useState(
        formData.workContacts || ""
    );
    const [errors, setErrors] = useState({});

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const validateForm = () => {
        const newErrors = {};

        if (!position.trim()) newErrors.position = "Должность обязательно";
        if (!workPlace.trim()) newErrors.workPlace = "Место работы обязательно";
        if (!workAddress.trim())
            newErrors.workAddress = "Адрес работы обязателен";

        if (workContacts.trim().length < 15)
            newErrors.workContacts =
                "Рабочие контакты должны быть заполнены до 15 символов";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            dataChange(5, { position, workPlace, workAddress, workContacts });
            next();
        }
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Typography variant="h6">Место работы и адрес работы</Typography>

            <TextField
                label="Место работы"
                value={workPlace}
                onChange={(e) => setWorkPlace(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.workPlace}
                helperText={errors.workPlace}
            />
            <TextField
                label="Должность"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.position}
                helperText={errors.position}
            />
            <TextField
                label="Адрес работы"
                value={workAddress}
                onChange={(e) => setWorkAddress(e.target.value)}
                fullWidth
                margin="normal"
                required
                error={!!errors.workAddress}
                helperText={errors.workAddress}
            />
            <MuiTelInput
                defaultCountry="KZ"
                onlyCountries={["KZ"]}
                label="Рабочие контакты"
                value={workContacts}
                onChange={(workPhone) => setWorkContacts(workPhone)}
                fullWidth
                inputProps={{ maxLength: 15 }}
                margin="normal"
                required
                error={!!errors.workContacts}
                helperText={errors.workContacts}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={back}>
                    Вернуться
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    Продолжить
                </Button>
            </Box>
        </Box>
    );
};

export default WorkDetails;
