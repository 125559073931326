import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    useMediaQuery,
    useTheme,
    Snackbar,
    Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Delete } from "@mui/icons-material";

const UploadDocuments = ({
    next,
    back,
    dataChange,
    formData = {},
    noRegistration,
}) => {
    const [idDocument, setIdDocument] = useState(formData.idDocument || []);
    const [techPassport, setTechPassport] = useState(
        formData.techPassport || []
    );
    const [bankStatement, setBankStatement] = useState(
        formData.bankStatement || []
    );
    const [additionalStatement, setAdditionalStatement] = useState(
        formData.additionalStatement || []
    );
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (formData.idDocument) setIdDocument(formData.idDocument);
        if (formData.techPassport) setTechPassport(formData.techPassport);
        if (formData.bankStatement) setBankStatement(formData.bankStatement);
        if (formData.additionalStatement)
            setAdditionalStatement(formData.additionalStatement);
    }, [formData]);

    const onDrop = (acceptedFiles, setFiles, label, isPhotoAllowed) => {
        const validExtensions = ["pdf", "jpeg", "jpg", "png"];
        const renamedFiles = acceptedFiles
            .map((file) => {
                const fileExtension = file.name.split(".").pop().toLowerCase();
                if (!validExtensions.includes(fileExtension)) {
                    setSnackbarMessage("Неправильный формат документа");
                    setSnackbarOpen(true);
                    return null;
                }
                if (file.type.startsWith("video/")) {
                    setSnackbarMessage("Неправильный формат документа");
                    setSnackbarOpen(true);
                    return null;
                }
                const newName = `${label}.${fileExtension}`;
                return new File([file], newName, { type: file.type });
            })
            .filter(Boolean);
        setFiles(renamedFiles.slice(0, 1));
    };

    const handleRemoveFile = (setFiles) => {
        setFiles([]);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!idDocument.length)
            newErrors.idDocument = "Удостоверение обязательно";
        if (!noRegistration && !techPassport.length)
            newErrors.techPassport = "Техпаспорт обязателен";
        if (!bankStatement.length)
            newErrors.bankStatement = "Выписка с БВУ обязательна";
        if (!additionalStatement.length)
            newErrors.additionalStatement =
                "Дополнительная выписка обязательна";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            dataChange(7, {
                idDocument,
                techPassport,
                bankStatement,
                additionalStatement,
            });
            next();
        }
    };

    const Dropzone = ({
        label,
        files,
        setFiles,
        error,
        inputLabel,
        accept,
        acceptedFormats,
        isPhotoAllowed,
    }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop: (acceptedFiles) =>
                onDrop(acceptedFiles, setFiles, inputLabel, isPhotoAllowed),
            accept,
            capture: isPhotoAllowed ? "environment" : false,
        });

        return (
            <>
                <Typography variant="h6">{label}</Typography>
                <Box
                    {...getRootProps()}
                    sx={{
                        border: "2px dashed grey",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        marginTop: 2,
                        marginBottom: 2,
                        borderColor: error ? "red" : "grey",
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>
                        {isDragActive
                            ? "Отпустите файлы здесь..."
                            : "Перетащите файлы сюда или нажмите для выбора"}
                    </Typography>
                    <Typography variant="body2">
                        Допустимые форматы: {acceptedFormats}
                    </Typography>
                </Box>
                {error && <Typography color="error">{error}</Typography>}
                <List>
                    {files.map((file, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={file.name} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    onClick={() => handleRemoveFile(setFiles)}
                                >
                                    <Delete />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </>
        );
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ padding: isMobile ? 2 : 3 }}>
            <Dropzone
                label="Удостоверение"
                files={idDocument}
                setFiles={setIdDocument}
                error={errors.idDocument}
                inputLabel="Удостоверение"
                accept={{
                    "application/pdf": [],
                    "image/jpeg": [],
                    "image/png": [],
                }}
                acceptedFormats=".PDF, .JPG, .PNG"
                isPhotoAllowed={true}
            />
            {!noRegistration && (
                <Dropzone
                    label="Техпаспорт"
                    files={techPassport}
                    setFiles={setTechPassport}
                    error={errors.techPassport}
                    inputLabel="Техпаспорт"
                    accept={{
                        "application/pdf": [],
                        "image/jpeg": [],
                        "image/png": [],
                    }}
                    acceptedFormats=".PDF, .JPG, .PNG"
                    isPhotoAllowed={true}
                />
            )}
            <Dropzone
                label="Выписка с БВУ за последние 6 месяцев"
                files={bankStatement}
                setFiles={setBankStatement}
                error={errors.bankStatement}
                inputLabel="Выписка_с_БВУ"
                accept={{
                    "application/pdf": [],
                }}
                acceptedFormats=".PDF"
                isPhotoAllowed={false}
            />
            <Dropzone
                label="Дополнительная выписка в виде справки с БВУ за последние 6 мес./12 мес."
                files={additionalStatement}
                setFiles={setAdditionalStatement}
                error={errors.additionalStatement}
                inputLabel="Дополнительная_выписка"
                accept={{
                    "application/pdf": [],
                }}
                acceptedFormats=".PDF"
                isPhotoAllowed={false}
            />
            <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={back}>
                    Вернуться
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    Продолжить
                </Button>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default UploadDocuments;
