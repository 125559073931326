import axios from "axios";

const sendFileToTelegram = async (chatId, zipBlob, clientFullName) => {
    const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_TOKEN;
    const TELEGRAM_API_URL = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendDocument`;

    const formData = new FormData();
    formData.append("chat_id", chatId);
    formData.append("document", new File([zipBlob], `${clientFullName}.zip`));

    try {
        await axios.post(TELEGRAM_API_URL, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return true;
    } catch (error) {
        return false;
    }
};

export default sendFileToTelegram;
