import {
    Document,
    Packer,
    Paragraph,
    Table as DocTable,
    TableCell as DocTableCell,
    TableRow as DocTableRow,
    BorderStyle,
    HeadingLevel,
} from "docx";

const toUpperCase = (text) => text.toUpperCase();

const generateDocx = async (data) => {
    const doc = new Document({
        styles: {
            default: {
                paragraph: {
                    spacing: { after: 200 },
                },
            },
        },
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        text: "Данные клиента",
                        heading: HeadingLevel.HEADING_1,
                        style: "default",
                    }),
                    new DocTable({
                        rows: [
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Точка оформления",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[0]?.tgChat?.tg_name ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Название салона",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[0]?.salonName ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Имя менеджера",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[0]?.managerName ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Телефон менеджера",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text:
                                                    data[0]?.managerPhone ||
                                                    "Не указано",
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Контактная информация клиента",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `ФИО: ${
                                                    data[1]?.clientFullName ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Телефон: ${
                                                    data[1]?.phoneNumber ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `ИИН: ${
                                                    data[1]?.iin || "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Семейный статус: ${
                                                    data[1]?.maritalStatus ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Продукт кредитования",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `${
                                                    data[2]?.productType ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            // Добавление объекта из data[2] в зависимости от его типа
                            ...(data[2]?.selectedBanks
                                ? [
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Выбранные банки",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      ...data[2].selectedBanks.map(
                                                          (bank) =>
                                                              new Paragraph({
                                                                  text: bank,
                                                                  style: "default",
                                                              })
                                                      ),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Стоимость автомобиля",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2].carCost ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Первоначальный взнос",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2]
                                                                  .initialPayment ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Сумма кредита",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2]
                                                                  .loanAmount ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Срок кредита",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2]
                                                                  .loanTerm ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                  ]
                                : []),
                            ...(data[2]?.country
                                ? [
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Страна",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2].country ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Стоимость автомобиля",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2].carCost ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Срок кредита",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2].term ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                      new DocTableRow({
                                          children: [
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text: "Общая сумма платежа",
                                                          style: "default",
                                                      }),
                                                  ],
                                                  borders: {
                                                      bottom: {
                                                          style: BorderStyle.SINGLE,
                                                          size: 1,
                                                      },
                                                  },
                                              }),
                                              new DocTableCell({
                                                  children: [
                                                      new Paragraph({
                                                          text:
                                                              data[2].result
                                                                  ?.totalPayment ||
                                                              "Не указано",
                                                          style: "default",
                                                      }),
                                                  ],
                                              }),
                                          ],
                                      }),
                                  ]
                                : []),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Детали автомобиля",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `Марка: ${
                                                    data[3]?.brand ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Модель: ${
                                                    data[3]?.model ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Год: ${
                                                    data[3]?.year ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Руль: ${
                                                    data[3]?.steering ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Объем двигателя: ${
                                                    data[3]?.engineVolume ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `VIN: ${
                                                    toUpperCase(data[3]?.vin) ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Адрес проживания",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `Город: ${
                                                    data[4]?.city ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Улица: ${
                                                    data[4]?.street ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Номер дома: ${
                                                    data[4]?.houseNumber ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Квартира: ${
                                                    data[4]?.apartment ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Подъезд: ${
                                                    data[4]?.entrance ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Место работы",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: `Должность: ${
                                                    data[5]?.position ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Место работы: ${
                                                    data[5]?.workPlace ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Адрес работы: ${
                                                    data[5]?.workAddress ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                            new Paragraph({
                                                text: `Контакты работы: ${
                                                    data[5]?.workContacts ||
                                                    "Не указано"
                                                }`,
                                                style: "default",
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new DocTableRow({
                                children: [
                                    new DocTableCell({
                                        children: [
                                            new Paragraph({
                                                text: "Экстренные контакты",
                                                style: "default",
                                            }),
                                        ],
                                        borders: {
                                            bottom: {
                                                style: BorderStyle.SINGLE,
                                                size: 1,
                                            },
                                        },
                                    }),
                                    new DocTableCell({
                                        children: [
                                            ...data[6]?.map(
                                                (contact, index) =>
                                                    new Paragraph({
                                                        text: `Контакт ${
                                                            index + 1
                                                        }: Имя: ${
                                                            contact.name ||
                                                            "Не указано"
                                                        }, Телефон: ${
                                                            contact.phone ||
                                                            "Не указано"
                                                        }`,
                                                        style: "default",
                                                    })
                                            ),
                                        ],
                                    }),
                                ],
                            }),
                        ],
                    }),
                ],
            },
        ],
    });

    const blob = await Packer.toBlob(doc);
    return blob;
};

export default generateDocx;
