import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch } from "react-redux";

import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import Header from "./components/Header";

import { auth } from "./firebase";
import { setUser, removeUser } from "store/slices/userSlice";
import Loading from "components/Loading";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.REACT_APP_NODE_ENV === "production") {
    disableReactDevTools();
}
const App = () => {
    const [isFetching, setIsFetching] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                dispatch(
                    setUser({
                        email: user.email,
                        id: user.uid,
                        token: user.accessToken,
                    })
                );
            } else {
                dispatch(removeUser());
            }
            setIsFetching(false);
        });
        return () => unsubscribe();
    }, [dispatch]);

    if (isFetching) {
        return <Loading />;
    }

    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<SignInPage />} />
                <Route path="*" element={<HomePage />} />
            </Routes>
        </>
    );
};

export default App;
