import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/use-auth";
import SignIn from "../components/auth/SignIn";
import ParticlesComponent from "components/ui/particles";

const LoginPage = () => {
    const { isAuth } = useAuth();

    if (isAuth) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <ParticlesComponent id="particle" />
            <SignIn />
        </>
    );
};

export default LoginPage;
