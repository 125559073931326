import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

const countries = [
    { label: "Америка", rate12: 0.15, rate24: 0.2 },
    { label: "Европа", rate12: 0.15, rate24: 0.2 },
    { label: "Корея", rate12: 0.15, rate24: 0.2 },
    { label: "Япония", rate12: 0.15, rate24: 0.2 },
    { label: "Китай", rate12: 0.17, rate24: 0.2 },
    { label: "СНГ", rate12: 0.17, rate24: 0.2 },
];

const Installment = ({ formData = {}, dataChange }) => {
    const [country, setCountry] = useState(formData.country || "");
    const [carCost, setCarCost] = useState(formData.carCost || "");
    const [term, setTerm] = useState(formData.term || "12");
    const [result, setResult] = useState(null);

    useEffect(() => {
        const calculateInstallment = () => {
            const selectedCountry = countries.find((c) => c.label === country);
            if (selectedCountry && carCost) {
                const rate =
                    term === "12"
                        ? selectedCountry.rate12
                        : selectedCountry.rate24;
                const totalPayment = (carCost * (1 + rate)).toFixed(2);
                setResult({ totalPayment });
            } else {
                setResult(null);
            }
        };

        calculateInstallment();
    }, [country, carCost, term]);

    useEffect(() => {
        dataChange(2, { country, carCost, term, result });
    }, [country, carCost, term, result, dataChange]);

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleCarCostChange = (event) => {
        setCarCost(event.target.value.replace(/\s/g, ""));
    };

    const handleTermChange = (event) => {
        setTerm(event.target.value);
    };

    return (
        <Box mt={2}>
            <Typography variant="h6">Рассчитать рассрочку</Typography>
            <FormControl fullWidth margin="normal">
                <InputLabel>Страна производитель</InputLabel>
                <Select value={country} onChange={handleCountryChange}>
                    {countries.map((c) => (
                        <MenuItem key={c.label} value={c.label}>
                            {c.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <NumericFormat
                customInput={TextField}
                thousandSeparator=" "
                label="Общая стоимость автомобиля (₸)"
                value={carCost}
                onChange={handleCarCostChange}
                fullWidth
                margin="normal"
                required
            />
            <Box mt={2}>
                <FormLabel component="legend">Срок</FormLabel>
                <RadioGroup value={term} onChange={handleTermChange} row>
                    <FormControlLabel
                        value="12"
                        control={<Radio />}
                        label="12 мес"
                    />
                    <FormControlLabel
                        value="24"
                        control={<Radio />}
                        label="24 мес"
                    />
                </RadioGroup>
            </Box>
            {result && (
                <Box mt={2}>
                    <Typography variant="h6">Результат</Typography>
                    <Typography>
                        Общая стоимость автомобиля:{" "}
                        <NumericFormat
                            value={result.totalPayment}
                            displayType={"text"}
                            thousandSeparator=" "
                            decimalSeparator=","
                            suffix=" ₸"
                        />
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default Installment;
