import React, { useState } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useMediaQuery,
    useTheme,
    Snackbar,
    Alert,
} from "@mui/material";
import AutoCredit from "./subStepBankOperation/AutoCredit";
import Installment from "./subStepBankOperation/Installment";

const SelectBankOperation = ({ next, back, dataChange, formData = {} }) => {
    const [productType, setProductType] = useState(formData.productType || "");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleProductChange = (event) => {
        setProductType(event.target.value);
    };

    const handleNext = () => {
        if (validateForm()) {
            const updatedFormData = {
                ...formData,
                productType,
            };
            dataChange(2, updatedFormData);
            next();
        } else {
            setErrorMessage("Пожалуйста, заполните все обязательные поля.");
            setOpenSnackbar(true);
        }
    };

    const validateForm = () => {
        if (productType === "Автокредитование") {
            return (
                formData.selectedBanks?.length > 0 &&
                formData.carCost &&
                formData.initialPayment &&
                formData.loanTerm &&
                (formData.selectedBanks.includes("Шинхан Финанс")
                    ? formData.uploadedDocs?.clientForm &&
                      formData.uploadedDocs?.clientConsent &&
                      formData.uploadedDocs?.clientConsentPKB
                    : true)
            );
        } else if (productType === "Рассрочка") {
            return formData.country && formData.carCost && formData.term;
        }
        return false;
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box
            p={isMobile ? 1 : 2}
            display="flex"
            justifyContent="center"
            minHeight="60svh"
        >
            <Card
                sx={{
                    maxWidth: 800,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <CardHeader
                    title="Выбор продукта кредитования"
                    titleTypographyProps={{ align: "center" }}
                    sx={{ bgcolor: "#000", color: "#ff0" }}
                />
                <Divider />
                <CardContent sx={{ flex: "1 1 auto" }}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">
                            Выберите продукт кредитования
                        </FormLabel>
                        <RadioGroup
                            value={productType}
                            onChange={handleProductChange}
                            row={!isMobile}
                        >
                            <FormControlLabel
                                value="Автокредитование"
                                control={<Radio />}
                                label="Автокредитование"
                            />
                            <FormControlLabel
                                value="Рассрочка"
                                control={<Radio />}
                                label="Рассрочка"
                            />
                        </RadioGroup>
                    </FormControl>
                    {productType === "Автокредитование" && (
                        <AutoCredit
                            next={next}
                            dataChange={dataChange}
                            formData={formData}
                        />
                    )}
                    {productType === "Рассрочка" && (
                        <Installment
                            next={next}
                            dataChange={dataChange}
                            formData={formData}
                        />
                    )}
                </CardContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: 2,
                    }}
                >
                    <Button variant="contained" onClick={back}>
                        Вернуться
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                    >
                        Продолжить
                    </Button>
                </Box>
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="warning"
                    sx={{ width: "100%" }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SelectBankOperation;
